<template>
    <div class="page-container">
        <img src="./banner.jpg" width="100%"/>
        <div class="contact-container">
            <div class="c-c-content clearfix">
                <div class="fl">
                    <p><span>公司地址：</span>上海市长宁区延安西路2558号1号楼</p>
                    <p><span>咨询电话：</span>021-62959001</p>
                    <p><span class="space">联系人：</span>周女士</p>
                    <p><span>办公时间：</span>工作日9:00~17:00</p>
                    <div class="qr-code">
                        <img src="./qrcode.jpg" width="138"/>
                        <p>微信公众号</p>
                    </div>
                </div>
                <div class="fr">
                  <div class="map-view"><img src="./map.png" alt="正经塾"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  // import AMapLoader from '@amap/amap-jsapi-loader';

	export default {
		components: {
		},
		data() {
			return {
				// show: true,
        // map: null,
        // infoWindow: null
			}
		},
    mounted(){
      // this.$nextTick(() => {
      //   this.initMap();
      // })
    },
		methods: {
      // initMap(){
      //   AMapLoader.load({
      //     key: '2d8099018f6f154c82e25749c91f80fa',             // 申请好的Web端开发者Key，首次调用 load 时必填
      //     version: '2.0',      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      //     plugins: [],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      //   }).then(AMap => {
      //     // 自定义点标记内容
      //     const markerContent = document.createElement("div");
      //     // 点标记中的图标
      //     const markerImg = document.createElement("img");
      //     markerImg.className = "markerlnglat";
      //     markerImg.src = "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png";
      //     markerImg.setAttribute('width', '25px');
      //     markerImg.setAttribute('height', '34px');
      //     markerContent.appendChild(markerImg);
      //
      //     // const icon = new AMap.Icon({
      //     //   size: new AMap.Size(15, 20),    // 图标尺寸
      //     //   image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',  // Icon的图像
      //     // });
      //
      //     const marker = new AMap.Marker({
      //       position: [121.397155, 31.198411]
      //     });
      //
      //     marker.setContent(markerContent);
      //
      //     marker.on('click', this.markerClick);
      //
      //     // 信息窗体构建
      //     this.infoWindow = new AMap.InfoWindow({
      //       content: "<p style=\"font-size:16px;color:#848484;padding:20px 10px 20px 10px;\">上海市长宁区延安西路2558号1号楼</p>",
      //       anchor: 'top-center'
      //     });
      //
      //     const map = new AMap.Map('map-container', {
      //       zoom: 15,   //级别
      //       center: [121.397155, 31.198411],   //中心点坐标,
      //       viewMode: '3D'    //是否为3D地图模式
      //     });
      //     this.map = map;
      //
      //     this.map.add(marker);
      //     this.infoWindow.open(map, map.getCenter());
      //
      //   }).catch(e => {
      //       console.log(e);
      //   });
      // },
      // 点击标记打开信息窗体
      markerClick(){
        if (this.infoWindow.getIsOpen() === false) {
          this.infoWindow.open();
        }
      }
		}
	}
</script>

<style lang="scss" scoped>
    .page-container {
        background-color: #fff;
    }
    .contact-container {
        background-color: #fff;
        padding: 60px 0;
        .c-c-content {
            @include section-mixin;
            .fl {
                .qr-code {
                    float: left;
                    margin-top: 40px;
                    text-align: center;
                }
                p {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    color: rgba(132, 132, 132, 1);
                    margin-top: 10px;
                    span {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        color: rgba(46, 53, 61, 1);
                        margin-right: 10px;
                        &.space {
                            margin-right: 6px;
                            letter-spacing: 6px;
                        }
                    }
                }
            }
        }
    }
    .map-view{
      width: 623px;
      height: 348px;
    }
</style>
