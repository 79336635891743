<template>
    <div>
        <img id="home_banner" width="100%" src="./banner.jpg"/>
        <div class="home-news-list">
            <ul class="clearfix">
                <li>
                    <a href="/news-detail/146" target="_blank" rel="noopener nofollow">
                        <p>研习回顾</p>
                        <p class="font_bold">娃哈哈：品牌的最大价值是诚信和产品质量</p>
                    </a>
                </li>
                <li>
                    <a href="/news-detail/144" target="_blank" rel="noopener nofollow">
                        <p>研习回顾</p>
                        <p class="font_bold">新希望：曾经的“痛点”就是创业“机会点”</p>
                    </a>
                </li>
                <li>
                    <a href="/news-detail/133" target="_blank" rel="noopener nofollow">
                        <p>研习回顾</p>
                        <p class="font_bold">福耀集团：做企业家，就是要修身齐家济国平天下</p>
                    </a>
                </li>
            </ul>
            <div class="news-more">
                <div><a href="/news-list" target="_blank" rel="noopener nofollow">查看更多 <img src="./arrow.png" alt="查看更多"></a></div>
            </div>
        </div>
        <div class="home-introduction">
            <div class="h-i-content">
                <div class="h-i-top clearfix">
                    <div class="fl">
                        <p class="title">掌门人正经塾</p>
                        <p class="text">
                          掌门人正经塾是一个为新形势下新一代商界领袖打造的创新型的实战效果导向的高端研习平台。正经塾以每年度“中国最具健康成长力30强上市公司”掌门人群体为基础，加上李东生、南存辉、陈东升、王石、郭广昌、刘永好、雷军、曹德旺、王文京、方洪波、杨元庆、周鸿祎等著名龙头企业掌门人群体，通过面访掌门人闭门深度交流形式，帮助塾生形成中国最优质的注重战略实效，穿越商界层次的事业生态资源圈。同时，正经塾将就塾生企业经营、投融资问题等的发展瓶颈，为塾生组织商界头部资源，进行定制化方案服务。
                        </p>
                    </div>
                    <span class="fr"><img alt src="./h-i-2.jpg" width="100%"/></span>
                </div>
                <div class="h-i-bottom clearfix">
                    <img class="arrow-bg1" src="./arrow-bg1.png"/>
                    <div class="item">
                        <span class="fl"><img alt src="./chenqiwei.png"/></span>
                        <p class="title">陈琦伟<span>发起人</span></p>
                        <p>亚商集团创始人，董事长；亚商投顾董事长，亚商资本创始合伙人；连续十二年担任上交所公司治理专家咨询委员会委员。</p>
                    </div>
                    <img class="arrow-bg2" src="./arrow-bg2.png"/>
                </div>
            </div>
        </div>
        <div class="home-research-targets">
            <p class="title">研习对象:“中国大师级企业家”和“最具健康成长力30强上市公司”掌门人</p>
            <div class="swiper-box" @mouseenter="mouseenterHandle" @mouseleave="mouseleaveHandle">
                <swiper class="study-content-target_swiper" :options="targetSwiperOption" >
                    <swiper-slide class="s-c-t_slide"><img src="../study-system/images/target1.png" alt="研习对象"></swiper-slide>
                    <swiper-slide class="s-c-t_slide"><img src="../study-system/images/target2.png" alt="研习对象"></swiper-slide>
                    <swiper-slide class="s-c-t_slide"><img src="../study-system/images/target3.png" alt="研习对象"></swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
            </div>
        </div>
        <div class="home-tutor">
            <div class="h-u-content">
                <div class="general-title">
                    <p class="mb_10">“访天下名师，悟掌门之道”</p>
                    <p class="pl_80">尊崇导师—专述分享企业领袖的“一本正经”
                        <a class="more" href="/study-system#study-content-tutor" target="_blank" rel="noopener nofollow">查看更多
                            <img src="./arrow.png" alt="查看更多">
                        </a>
                    </p>
                </div>
                <div  class="h-u-list">
                    <ul class="clearfix">
                        <li>
                            <span><img src="./cdw.png"/></span>
                            <p>
                                <span>曹德旺</span><br/>
                                福耀玻璃创始人
                            </p>
                        </li>
                        <li>
                            <span><img src="./zqh.png"/></span>
                            <p>
                                <span>宗庆后</span><br/>
                                娃哈哈创始人
                            </p>
                        </li>
                        <li>
                            <span><img src="./nch.png"/></span>
                            <p>
                                <span>南存辉</span><br/>
                                正泰集团创始人
                            </p>
                        </li>
                        <li>
                            <span><img src="./cds.png"/></span>
                            <p>
                                <span>陈东升</span><br/>
                                泰康集团创始人
                            </p>
                        </li>
                        <li>
                            <span><img src="./ws.png"/></span>
                            <p>
                                <span>王石</span><br/>
                                万科创始人
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="home-get">
            <div class="h-g-content">
                <p class="general-title">在这里你将获得</p>
                <ul class="clearfix">
                    <li>
                        <span><i class="iconfont iconrencaiduijie" /></span>
                        <p>掌门面对面言传身教</p>
                    </li>
                    <li>
                        <span><i class="iconfont iconcailiaozhiding" /></span>
                        <p>实地研习体验</p>
                    </li>
                    <li>
                        <span><i class="iconfont iconshizililiang" /></span>
                        <p>顶层人脉资源圈</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="home-partner">
            <div class="h-p-content">
                <p class="general-title">合作高校</p>
                <ul class="h-p-list clearfix">
                    <li class="h-p-l-img">
                        <p>
                            <img class="bg" src="./fd.jpg" width="100%"/>
                            <img class="logo" src="./fd-logo.png"/>
                        </p>
                        <div class="h-p-l-i-modal">
                            <p class="title">复旦管院简介</p>
                            <p class="desc">复旦大学管理学院肩负着“扎根于进步的中国、致力于创新性研究，培养既具国际视野、又深谙中国国情的学术专才、商业精英和社会领导者”的时代使命，不仅实现了教学与科研水平的全方位提升，而且为中国经济的腾飞培养了大量高素质的管理人才。学院不断吸收前沿管理思想，不断扎根本土商业实践，不断创新人才培养模式，开创出一条属于中国的管理教育探索与发展之路。</p>
                        </div>
                    </li>
                    <li class="text">
                        <p class="t-title mt_50">正经塾-复旦管院合作</p>
                        <p class="t-desc">正经塾和复旦管院合作创建中国第一家后EMBA工作站</p>
                        <ul class="clearfix mt_60">
                            <li>
                                <i class="iconfont iconanli"></i>
                                <p>案例研究</p>
                            </li>
                            <li>
                                <i class="iconfont icongongxiang-"></i>
                                <p>资源共享</p>
                            </li>
                            <li>
                                <i class="iconfont iconyanjiu-"></i>
                                <p>课程创新</p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="h-p-list clearfix">
                    <li class="text">
                        <p class="t-title mt_70">正经塾-交大安泰合作</p>
                        <ul class="clearfix mt_80">
                            <li>
                                <i class="iconfont iconhexinjiazhixiaotu"></i>
                                <p>行业商业<br/>价值深度研判</p>
                            </li>
                            <li>
                                <i class="iconfont icongongxiang-"></i>
                                <p>资源共享</p>
                            </li>
                            <li>
                                <i class="iconfont iconyanjiu-"></i>
                                <p>课程创新</p>
                            </li>
                        </ul>
                    </li>
                    <li class="h-p-l-img">
                        <p>
                            <img class="bg" src="./jd.jpg" width="100%"/>
                            <img class="logo" src="./jd-logo.png"/>
                        </p>
                        <div class="h-p-l-i-modal">
                            <p class="title">交大安泰经管院简介</p>
                            <p class="desc">上海交通大学安泰经济与管理学院的前身可追溯到1903年的“南洋公学高等商务学堂”，具有光荣的历史和传统。自1984年恢复建院以来，学院致力于培养具有国际竞争力的经济和管理人才，推崇在科学研究和学术思想方面的创新，以及服务社会经济发展和企业成长，经过30多年的努力，学院已经建设成为一所国内领先、国际知名的现代化商学院，是国内第一家通过AMBA、EQUIS、AACSB三大权威认证的商学院。</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {swiper, swiperSlide} from 'vue-awesome-swiper'

export default {
    components: {
        swiper,
        swiperSlide
    },
	data() {
		return {
			targetSwiperOption: {
                slidesPerView: 'auto',
                loop : true,
                spaceBetween: -5,
                centeredSlides: true,
                autoplay: {
                    disableOnInteraction: false,
                    delay: 3500
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            prevBtn: null,
            nextBtn: null
        }
    },
    computed: {
        globalScrollTop() {
            return this.$store.state.scrollTop;
        }
    },
    mounted(){
        this.$nextTick( () => {
            const prev = document.querySelector('.swiper-button-prev');
            const next = document.querySelector('.swiper-button-next');
            this.prevBtn = prev;
            this.nextBtn = next;
        })
    },
    methods: {
        mouseenterHandle(){
            this.prevBtn.style.display = 'block';
            this.nextBtn.style.display = 'block';
        },
        mouseleaveHandle(){
            this.prevBtn.style.display = 'none';
            this.nextBtn.style.display = 'none';
        }
    }
}
</script>

<style lang="scss" scoped>
    .animation-generic {
        animation-duration: 1s;
        animation-delay: .2s;
        animation-timing-function: ease;
        animation-fill-mode: both;
        will-change: transfrom;
    }
    img{
        vertical-align: middle;
    }
    .general-title, .home-research-targets .title{
        position: relative;
        line-height:40px;
        font-size: 30px;
        color: #2E353D;
        text-align: center;
        &::after{
            content: '';
            position: absolute;
            width: 60px;
            height: 3px;
            background-color: #B50707;
            bottom: -6px;
            left: 50%;
            margin-left: -30px;
        }
    }
    .home-news-list{
        height: 78px;
        background-color: #fff;
        padding-top: 30px;
        box-sizing: border-box;
        ul, .news-more div{
            @include section-mixin
        }
         li{
            float: left;
            width: 361px;
            /*height: 105px;*/
            background: #FFFFFF;
            box-shadow:0 3px 16px rgba(46,53,61,0.02);
            border-radius:5px;
            text-align: left;
            margin-right: 20px;
            padding: 22px 20px;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 0.3s ease;
             p{
                 font-size: 16px;
                 color: #848484;
             }
             .font_bold{
                 font-size: 18px;
                 font-weight: bold;
                 color: #2E353D;
                 margin-top: 10px;
                 line-height: 22px;
             }
             &:hover{
                 transform: translateY(-6px);
                 transition: all 0.3s ease;
             }
        }
         li:last-child{
            margin-right: 0;
        }
        .news-more{
            text-align: right;
            padding-top: 18px;
            background-color: #F7F8FA;
            a{
                cursor: pointer;
                color: #848484;
                font-size: 14px;
            }
            img{
                margin-bottom: 4px;
            }
        }
    }
    .home-introduction {
        background-color: #F7F8FA;
        padding-top: 70px;
        padding-bottom: 60px;
        .h-i-content{
            @include section-mixin;
            .h-i-top{
                position: relative;
                background-color: #fff;
                padding-right: 440px;
                margin-top: 65px;
                .fl {
                    font-size: 16px;
                    color: #848484;
                    padding: 40px 40px 35px 85px;
                    line-height: 28px;
                    .title{
                        position: relative;
                        font-size: 24px;
                        color: #2E353D;
                        line-height: 31px;
                        margin-bottom: 5px;
                        &::before{
                            content: '';
                            background: url("./symbol.png") 42px -10px no-repeat;
                            position: absolute;
                            width: 85px;
                            left: -85px;
                            height: 30px;
                            top: -20px;
                        }
                    }
                }
                .fr{
                    margin-right: -440px;
                    width: 440px;
                    overflow: hidden;
                }
            }
            .h-i-bottom{
                position: relative;
                padding-top: 40px;
                .item {
                    margin: 10px auto 0;
                    padding: 30px 20px;
                    width: 552.5px;
                    box-sizing: border-box;
                    background-color: #fff;
                    span.fl {
                        margin-right: 12px;
                    }
                    .title{
                        line-height:31px;
                        font-size: 24px;
                        color: #2E353D;
                        margin-bottom: 12px;
                        span{
                            font-size: 18px;
                            line-height:24px;
                            margin-left: 15px;
                        }
                    }
                    p {
                        line-height:28px;
                        font-size: 16px;
                        color: #848484;
                    }
                }
                .arrow-bg1{
                    position: absolute;
                    top: 50%;
                    left: 70px;
                    margin-top: -80px;
                }
                .arrow-bg2{
                    position: absolute;
                    top: 50%;
                    right: 70px;
                    margin-top: -80px;
                }
            }
        }
    }
    .home-research-targets{
        height: 538px;
        background-color: #fff;
        padding: 60px 0;
        box-sizing: border-box;
        .title{
            font-size: 30px;
            color: #2E353D;
            margin-bottom: 50px;
            text-align: center;
        }
        .swiper-box{
            @include section-mixin;
        }
        .study-content-target_swiper{
            @include section-mixin;
            .s-c-t_slide{
                width: 723px;
            }
            .swiper-button-prev, .swiper-button-next{
                display: none;
            }
            .swiper-button-prev{
                background-image: url("./swiper-button.png");
            }
            .swiper-button-next{
                background-image: url("./swiper-button.png");
                transform: rotate(180deg);
            }
            .swiper-button-prev, .swiper-button-next{
                background-size: 32px 32px;
            }
        }

    }
    .home-tutor{
        padding: 60px 0;
        background-color: #F7F8FA;
        .h-u-content{
            @include section-mixin;
            .mb_10{
                margin-bottom: 5px;
            }
            .pl_80{
                padding-left: 80px;
            }
            .h-u-list{
                margin-top: 50px;
                padding-top: 63px;
                background-color: transparent;
                li {
                    position: relative;
                    float: left;
                    width: 217px;
                    height: 160px;
                    background-color: #fff;
                    text-align: center;
                    &+li {
                        margin-left: 10px;
                    }
                    &>span{
                        display: inline-block;
                        margin: -57px 0 0 0;
                    }
                    p{
                        color: #2E353D;
                        font-size: 16px;
                        line-height:24px;
                        span {
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
    .home-get{
        background: url(./get-bg.jpg) bottom center fixed;
        padding: 100px 0;
        .h-g-content{
            @include section-mixin;
            .general-title{
                color: #fff;
            }
            ul {
                margin-top: 50px;
                li{
                    float: left;
                    width: 33.333%;
                    text-align: center;
                    span{
                        display: inline-block;
                        width: 121px;
                        height: 121px;
                        line-height: 121px;
                        border-radius: 50%;
                        border:1px solid #fff;
                        i {
                            color: #fff;
                            font-size: 40px;
                        }
                    }
                    p {
                        line-height:31px;
                        font-size:24px;
                        color: #fff;
                        margin-top: 24px;
                    }
                }
            }
        }
    }
    .home-partner{
        background-color: #F7F8FA;
        padding: 60px 0 50px 0;
        .h-p-content{
            @include section-mixin;
            .h-p-list {
                height: 367px;
                margin-top: 50px;
                & > li {
                    float: left;
                    width: 50%;
                    height: 359px;
                    background-color: #fff;
                    overflow: hidden;
                    &.h-p-l-img{
                        position: relative;
                        p {
                            .bg {
                                transition: all 0.7s ease;
                            }
                            .logo {
                                position: absolute;
                                left: 10px;
                                bottom: 10px;
                            }
                            &:hover{
                                .bg{
                                    transform: scale(1.2);
                                }
                            }
                        }
                        .h-p-l-i-modal{
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            padding: 46px 56px;
                            color: #fff;
                            box-sizing: border-box;
                            .title{
                                line-height:31px;
                                font-size: 24px;
                                text-align: center;
                                margin-bottom: 20px;
                            }
                            .desc{
                                font-size: 18px;
                                line-height:28px;
                            }
                        }
                    }
                    &.text {
                        text-align: center;
                        .t-title{
                            font-size: 24px;
                            color: #2E353D;
                            line-height:31px;
                        }
                        .t-desc{
                            line-height:28px;
                            font-size: 18px;
                            color: #848484;
                            margin-top: 15px;
                        }
                        ul{
                            li {
                                float: left;
                                width: 33.333%;
                                height: 186px;
                                box-sizing: border-box;
                                text-align: center;
                                &+li {
                                    border-left: 1px solid rgba(132, 132, 132, .2);
                                }
                                i {
                                    font-size: 36px;
                                    color: #2E353D;
                                }
                                p {
                                    font-size: 24px;
                                    color: #2E353D;
                                    line-height:31px;
                                    margin-top: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
