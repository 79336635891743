<template>
    <div class="float-bar">
        <div class="float-bar-inner">
            <div class="float-bar-item">
                <ul class="float-bar-tool">
                    <li>
                        <i class="iconfont iconweixin"/>
                        <p>公众号</p>
                        <div class="fb-hover-box"><img src="../footer/gzh_qrcode.jpg" alt/></div>
                    </li>
                    <li>
                        <a href="/advisory">
                        <i class="iconfont iconxinxi-"/>
                        <p>立即咨询</p>
                        </a>
                    </li>
                    <li>
                        <i class="iconfont iconerweima-"/>
                        <p>扫码报名</p>
                        <div class="fb-hover-box"><img src="../footer/sign_qrcode.jpg" alt/></div>
                    </li>
                </ul>
                <back-top/>
            </div>
        </div>
    </div>
</template>

<script>
	import BackTop from '../back-top'

	export default {
		components: {
			BackTop
		}
	}
</script>

<style lang="scss" scoped>
    @keyframes tipsShow {
        0% {
            opacity: .2;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
            filter: alpha(opacity=20);
            right: 75px
        }

        to {
            opacity: 1;
            -ms-filter: none;
            filter: none;
            right: 95px
        }
    }

    .float-bar {
        position: fixed;
        z-index: 99999;
        right: 0;
        transition: top .2s;
        top: 0;
        height: 100%;
        display: table;
        pointer-events: none;
        .float-bar-inner {
            display: table-cell;
            vertical-align: top !important;
            padding-top: 250px;
            .float-bar-item {
                position: relative;
                pointer-events: auto;
                .float-bar-tool {
                    width: 76px;
                    padding: 30px 0;
                    background-color: #fff;
                    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
                    border-radius: 5px;
                    li {
                        position: relative;
                        padding-top: 4px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 21px;
                        color: #848484;
                        text-align: center;
                        cursor: pointer;
                        a{
                            display: inline-block;
                        }
                        &:hover {
                            .fb-hover-box {
                                display: block;
                                animation: tipsShow .25s ease-in 1 forwards
                            }
                            i {
                                color: #B50707;
                            }
                        }
                        & + li {
                            margin-top: 30px;
                        }
                        i {
                            font-style: normal;
                            font-size: 26px;
                        }
                        p {
                            margin-top: 5px;
                        }
                        .fb-hover-box {
                            display: none;
                            position: absolute;
                            top: 0;
                            right: 95px;
                            padding: 5px;
                            border-radius: 3px;
                            background-color: #fff;
                            box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .06), 0 15px 40px 0 rgba(0, 0, 0, .12);
                            margin-top: -30px;
                            &.tel {
                                background-color: #ffaf00;
                                white-space: nowrap;
                                padding: 20px;
                                margin-top: -3.5px;
                                color: #fff;
                                &::after {
                                    border-color: transparent transparent transparent #ffaf00;
                                }
                            }
                            &::after {
                                position: absolute;
                                content: '';
                                border-width: 16px;
                                border-style: solid;
                                border-color: transparent transparent transparent #fff;
                                top: 50%;
                                right: -26px;
                                margin-top: -16px;
                            }
                            img {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
