<template>
    <div class="page-container">
        <img src="./banner.jpg" width="100%" />
        <div class="advisory-target">
            <div class="a-t-content">
                <p class="general-title mt_60">塾生对象</p>
                <div class="a-t-top clearfix">
                    <div class="fl">
                        <p><span>1</span>高端制造、信息技术、医疗健康和消费升级四大产业领域产业链的上下游企业<br/>创始人、股东、高级管理人员</p>
                        <p><span>2</span>大型民营企业的未来接班人、创二代</p>
                        <p><span>3</span>专注于产业和上市公司发展的成熟价值投资者</p>
                        <p><span>4</span>具有两名有社会知名度的企业家、投资机构合伙人、大学教授或社会知名人士推荐</p>
                    </div>
                    <span class="fr"><img alt src="./target.jpg" width="100%"/></span>
                </div>
            </div>
        </div>
        <div class="advisory-process">
            <div class="a-p-content">
                <p class="general-title">申请流程</p>
                <ul class="clearfix">
                    <li><em>01</em><span><i class="iconfont iconbaoming-" /></span><p>线上报名<br/>或现场填写报名申请表</p></li>
                    <li><em>02</em><span><i class="iconfont icontuijian" /></span><p>资格筛选<br/>（报名表/推荐信）</p><i class="iconfont iconzu"></i></li>
                    <li><em>03</em><span><i class="iconfont iconmianshi-" style="font-size: 46px"/></span><p>面试</p><i class="iconfont iconzu"></i></li>
                    <li><em>04</em><span><i class="iconfont iconmenzhenjiaofei" /></span><p>缴费入学</p><i class="iconfont iconzu"></i></li>
                </ul>
                <p class="btn"><a href="https://www.wjx.top/jq/43076156.aspx" target="_blank" rel="noopener nofollow">即刻报名&nbsp;&nbsp;与掌门面对面</a></p>
            </div>
        </div>
        <div class="advisory-contact">
            <div class="a-c-content">
                <p class="general-title">联系方式</p>
                <ul class="clearfix">
                    <li>联 系 人：殷先生</li>
                    <li>咨询电话：（座机）021-62959001<br/><span/>（手机）+86 13701773940</li>
                    <li>办公时间：9:00～17:00（工作日）</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
	}
</script>

<style lang="scss" scoped>
    .page-container{
        padding-bottom: 65px;
        background-color: #F7F8FA;
    }
    .advisory-target{
        background-color: #fff;
        padding-bottom: 60px;
        overflow: hidden;
        .a-t-content {
            @include section-mixin;
            .a-t-top{
                position: relative;
                background-color: #fff;
                padding-right: 440px;
                margin-top: 65px;
                .fl {
                    font-size:16px;
                    font-weight:400;
                    line-height:28px;
                    color:rgba(46,53,61,1);
                    p {
                        position: relative;
                        padding-left: 35px;
                        margin-top: 20px;
                        span {
                            position: absolute;
                            left: 0;
                            top: 5px;
                            width: 20px;
                            line-height: 20px;
                            border-radius: 50%;
                            text-align: center;
                            background-color: #2E353D;
                            color: #fff;
                        }
                    }
                }
                .fr{
                    margin-right: -440px;
                    width: 440px;
                }
            }
        }
    }
    .advisory-process{
        background-color: #F7F8FA;
        padding: 60px 0;
        .a-p-content{
            @include section-mixin;
            ul {
                margin-top: 70px;
                li {
                    position: relative;
                    float: left;
                    width: 25%;
                    text-align: center;
                    em {
                        position: absolute;
                        font-style: normal;
                        color: rgba(46, 53, 61, .1);
                        font-size:40px;
                        line-height:28px;
                        top: -7px;
                    }
                    &+li {
                        &>i {
                            position: absolute;
                            font-size: 14px;
                            color: #848484;
                            left: -50px;
                            top: 52px;
                        }
                    }
                    &>span{
                        display: inline-block;
                        width: 116px;
                        height: 116px;
                        line-height: 116px;
                        border-radius: 50%;
                        border:1px solid rgba(46,53,61,1);
                        i {
                            font-size: 40px;
                            color: #2E353D;
                        }
                    }
                    &>p {
                        font-size:18px;
                        font-weight:400;
                        line-height:28px;
                        color:rgba(46,53,61,1);
                        margin-top: 20px;
                    }
                }
            }
            .btn{
                text-align: center;
                margin-top: 70px;
                a {
                    display: inline-block;
                    line-height: 46px;
                    padding: 0 20px;
                    color: #fff;
                    font-size: 18px;
                    background-color: #B50707;
                    border-radius:10px;
                    box-shadow:0 3px 16px rgba(103,4,4,0.24);
                    cursor: pointer;
                }
            }
        }
    }
    .advisory-contact{
        padding: 60px 0;
        background-color: #fff;
        .a-c-content{
            @include section-mixin;
            ul {
                margin-top: 55px;
                li {
                    float: left;
                    width: 33.333%;
                    line-height:28px;
                    font-size:18px;
                    font-weight:400;
                    color: #2E353D;
                    text-align: center;
                    span {
                        display: inline-block;
                        width: 122px;
                    }
                }
            }
        }
    }
</style>
