<template>
    <footer class="app-footer">
        <div class="app-footer-top">
            <div class="ft-content">
                <ul class="ft-columns">
                    <li class="ft-column w_50">
                        <h3>咨询热线</h3>
                        <ul class="ft-columns-sub">
                            <li class="tel"><img src="./tel.png" alt />021—62959001</li>
                            <li>工作日 9:00~17:00</li>
                            <li>联系地址：上海市长宁区延安西路2558号1号楼</li>
                            <li class="abc-logo"><a href="https://www.abc-group.cn" target="_blank" rel="noopener nofollow"><img src="./abc-logo.png" alt=""></a></li>
                        </ul>
                    </li>
                    <li class="ft-column w_25">
                        <h3>公司信息</h3>
                        <ul class="ft-columns-sub">
                            <li class="mt_16"><a href="/about">关于我们</a></li>
                            <li><a href="/contact-us">联系我们</a></li>
                        </ul>
                    </li>
                    <li class="ft-column w_25">
                        <h3>友情链接</h3>
                        <ul class="ft-columns-sub">
                            <li><a href="https://www.abc-group.cn" target="_blank" rel="noopener nofollow">亚商集团</a></li>
                            <li><a href="https://www.abctougu.cn" target="_blank" rel="noopener nofollow">亚商投顾</a></li>
                        </ul>
                    </li>
                </ul>
                <ul class="qr-code-columns">
                    <li>
                        <img src="./gzh_qrcode.jpg"/>
                        <p>正经塾微信公众号</p>

                    </li>
                    <li>
                        <img src="./sign_qrcode.jpg"/>
                        <p>扫码立即报名</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="app-footer-bottom">
            <p>Copyright © 2019-2022 上海亚商正经塾教育科技有限公司 版权所有 30zjs.cn <a href="https://beian.miit.gov.cn/#/Integrated/index"  target="_blank" rel="noopener nofollow"><img src="./badge.png"/>沪ICP备19039347号-1</a></p>
        </div>
    </footer>
</template>

<script>
	export default {

	}
</script>

<style lang="scss" scoped>
    .app-footer {
        margin-top: -295px;
    }

    .app-footer-top {
        background-color: #2E353D;
        .ft-content {
            position: relative;
            padding: 36px 0 36px 0;
            background-color: #2E353D;
            @include section-mixin;
        }
        .ft-columns {
            margin-right: 265px;
            background-color: #2E353D;
            overflow: hidden;
            color: #C1C1C1;
            font-size: 16px;
            line-height: 30px;
            .ft-column {
                float: left;
                width: 33%;
                h3 {
                    position: relative;
                    color: #F7F8FA;
                    margin-bottom: 24px;
                    &::after {
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: 0;
                        width: 34px;
                        height: 1px;
                        background-color: #B50707;
                    }
                }
                .ft-columns-sub {
                    overflow: hidden;
                    .tel{
                        font-size: 24px;
                        color: #fff;
                        img{
                            vertical-align: text-bottom;
                            margin-right: 13px;
                        }
                    }
                    a {
                        color: inherit;
                    }
                }
            }
            .w_50{
                width: 50%;
            }
            .w_25{
                width: 25%;
            }
            .abc-logo{
                margin-top: 32px;
                a{
                    display: inline-block;
                    padding: 6px 12px;
                    border: 1px solid #52585E;
                }
            }
        }
        .qr-code-columns{
            position: absolute;
            right: 0;
            top: 52px;
            overflow: hidden;
            li {
                float: left;
                text-align: center;
                color: #C1C1C1;
                p {
                    margin-top: 10px;
                }
                & + li {
                    margin-left: 40px;
                }
            }
        }
    }

    .app-footer-bottom {
        background-color: #242A30;
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            color: #C1C1C1;
            text-align: center;
            @include section-mixin;
            a {
                color: inherit;
                cursor: pointer;
            }
            img {
                margin: 0 6px 0 52px;
                vertical-align: -2px;
            }
        }
    }
</style>
