<template>
    <div class="study-case">
        <div class="study-information">
            <h2>信息技术行业</h2>
                <ul class="clearfix">
                    <li v-for="(item,index) in technologySmallList" :key="index" :style="{marginRight: item.mr === false ? '0' : ''}">
                        <a :href="item.href ? item.href : ''" :target="item.href ? '_blank' : ''" rel="noopener nofollow">
                            <img class="pic-url" :src="item.picUrl" :alt="item.alt" width="100%">
                            <img v-if="item.icon" class="icon" :src="item.icon" :alt="item.alt">
                            <span v-if="item.nOpen" class="no-open">
                            <i class="iconfont iconshalou"></i>
                            <span class="text">敬请期待</span>
                        </span>
                            <span class="time">{{item.time}}</span>
                        </a>
                    </li>
                </ul>
            <collapse>
                <ul class="clearfix" v-show="showAll">
                    <li v-for="(item,index) in technologyBehindList" :key="index" :style="{marginRight: item.mr === false ? '0' : ''}">
                        <a :href="item.href ? item.href : ''" :target="item.href ? '_blank' : ''" rel="noopener nofollow">
                            <img class="pic-url" :src="item.picUrl" :alt="item.alt" width="100%">
                            <img v-if="item.icon" class="icon" :src="item.icon" :alt="item.alt">
                            <span v-if="item.nOpen" class="no-open">
                            <i class="iconfont iconshalou"></i>
                            <span class="text">敬请期待</span>
                        </span>
                            <span class="time">{{item.time}}</span>
                        </a>
                    </li>
                </ul>
            </collapse>
            <p class="more"><span @click="showAll = !showAll">{{showAll ? '点击收起更多' : '展开查看更多'}}</span></p>
        </div>
        <div class="study-healthy">
            <h2>大健康行业</h2>
            <ul class="clearfix">
                <li v-for="(item,index) in caseList.healthy" :key="index">
                    <a :href="item.href ? item.href : ''" :target="item.href ? '_blank' : ''" rel="noopener nofollow">
                        <img class="pic-url" :src="item.picUrl" :alt="item.alt" width="100%">
                        <img v-if="item.icon" class="icon" :src="item.icon" :alt="item.alt">
                        <span v-if="item.nOpen" class="no-open">
                            <i class="iconfont iconshalou"></i>
                            <span class="text">敬请期待</span>
                        </span>
                        <span class="time">{{item.time}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="study-product">
            <h2>高端制造行业</h2>
            <ul class="clearfix">
                <li v-for="(item,index) in caseList.product" :key="index">
                    <a :href="item.href ? item.href : ''" :target="item.href ? '_blank' : ''" rel="noopener nofollow">
                        <img class="pic-url" :src="item.picUrl" :alt="item.alt" width="100%">
                        <img v-if="item.icon" class="icon" :src="item.icon" :alt="item.alt">
                        <span v-if="item.nOpen" class="no-open">
                            <i class="iconfont iconshalou"></i>
                            <span class="text">敬请期待</span>
                        </span>
                        <span class="time">{{item.time}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import collapse from '../../lib/collapse'
export default {
    name: "index",
    components: {
        collapse
    },
    data (){
        return {
            showAll: false,
            technologySmallList: [],
            technologyBehindList: [],
            caseList: {
                technology: [
                    {
                        picUrl: require('./images/hs.jpg'),
                        icon: require('./images/hs-icon.png'),
                        time: '2019.10.23',
                        alt: '恒生电子',
                        href: '/study-case-detail/0'
                    },
                    {
                        picUrl: require('./images/yyou.jpg'),
                        icon: require('./images/yyou-icon.png'),
                        time: '2019.09.24',
                        alt: '用友',
                        href: '/study-case-detail/1'
                    },
                    {
                        picUrl: require('./images/baoxin.jpg'),
                        icon: require('./images/baoxin-icon.png'),
                        time: '2019.10.10',
                        alt: '宝信软件',
                        href: '/study-case-detail/2',
                        mr: false
                    },
                    {
                        picUrl: require('./images/cyhk.jpg'),
                        icon: require('./images/cyhk-icon.png'),
                        time: '2019.09.22',
                        alt: '创业慧康',
                        href: '/study-case-detail/3'
                    }
                ],
                healthy: [
                    {
                        picUrl: require('./images/antu.jpg'),
                        icon: require('./images/antu-icon.png'),
                        time: '2019.11.12',
                        alt: '安图生物',
                        href: '/study-case-detail/101',
                    },
                    {
                        picUrl: require('./images/healthy2.jpg'),
                        icon: '',
                        time: '',
                        alt: '',
                        nOpen: true
                    },
                    {
                        picUrl: require('./images/healthy2.jpg'),
                        icon: '',
                        time: '',
                        alt: '',
                        nOpen: true,
                        mr: false
                    }
                ],
                product: [
                    {
                        picUrl: require('./images/tcl.jpg'),
                        icon: require('./images/tcl-icon.png'),
                        time: '2019.11.11',
                        alt: 'TCL',
                        href: '/study-case-detail/201',
                    },
                    {
                        picUrl: require('./images/jack.jpg'),
                        icon: require('./images/jack-icon.png'),
                        time: '2019.11.28',
                        alt: '杰克',
                        href: '/study-case-detail/202',
                    },
                    {
                        picUrl: require('./images/healthy2.jpg'),
                        icon: '',
                        time: '',
                        alt: '',
                        nOpen: true,
                        mr: false
                    }
                ],
            }
        }
    },
    created(){
      let technologyAllList = JSON.parse(JSON.stringify(this.caseList.technology));
      this.technologySmallList = technologyAllList.slice(0, 3);
      this.technologyBehindList = technologyAllList.slice(3, technologyAllList.length);
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.study-case{
    padding-bottom: 60px;
    margin-top: 100px;
    background-color: #F7F8FA;
    overflow: hidden;
    .study-information{
        height: auto;
    }
    .study-information, .study-healthy, .study-product{
        padding: 50px 0;
        background-color: #F7F8FA;
        box-sizing: border-box;
        h2{
            text-align: center;
            font-size: 30px;
            color: #2E353D;
            line-height: 40px;
            &::after{
                display: block;
                content: '';
                overflow: hidden;
                width: 60px;
                height: 3px;
                background-color: #B50707;
                margin: 4px auto 30px;
            }
        }
        ul{
            @include section-mixin
            li{
                float: left;
                width: 362px;
                height: 203px;
                margin-right: 19px;
                overflow: hidden;
                position: relative;
                margin-bottom: 15px;
                a{
                    display: inline-block;
                }
                .pic-url{
                    transition: all 0.7s ease;
                }
                .icon{
                    position: absolute;
                    bottom: 3px;
                    left: 15px;
                }
                .time{
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    color: #B8BABB;
                    font-size: 14px;
                }
                .no-open{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 80px;
                    height: 115px;
                    margin-top: -57.5px;
                    margin-left: -40px;
                    color: #fff;
                    i{
                        font-size: 80px;
                    }
                    .text{
                        font-size: 20px;
                        line-height: 40px;
                    }
                }
            }
            li:last-child{
                margin-right: 0;
            }
            li:hover .pic-url{
                transform: scale(1.2);
                transition: all 0.7s ease;
            }
        }
        .more{
            text-align: center;
            padding-top: 30px;
            span{
                cursor: pointer;
                display: inline-block;
                width: 148px;
                height: 38px;
                line-height: 38px;
                border: 1px solid #848484;
                border-radius:10px;
                color: #848484;
            }
        }
    }
    .study-healthy, .study-product{
        height: 378px;
        background-color: #fff;
    }
    .study-product{
        background-color: #F7F8FA;
    }
}
</style>
