import Vue from 'vue';
import Vuex from 'vuex';


Vue.use(Vuex);

export default new Vuex.Store({
	state    : {
		scrollTop: 0
	},
	getters   : {
		getGlobalScrollTop(state) {
			return state.scrollTop;
		}
	},
	mutations: {
		setGlobalScrollTop(state, value) {
			state.scrollTop = value;
		}
	}
});
