<template>
    <div class="news">
        <div class="banner"><img src="../news-list/images/banner.jpg" alt="新闻详情" width="100%"></div>
        <div class="breadcrumb">
            <a href="/">首页</a>
            >
            <span @click="gobackNewsList(true)">资讯中心</span>
            >
            <span @click="gobackNewsList(false)">{{formatNewsType(newsInfo.type)}}</span>
            >
            <font color="#2E353D">详情</font>
        </div>
        <div class="news-content" v-if="newsInfo">
            <div class="title">{{newsInfo.title}}</div>
            <p class="time">
                <span v-if="newsInfo.time">发布时间：{{newsInfo.time}}</span>
                <span class="ml_20" v-if="newsInfo.source">{{newsInfo.source}}</span>
            </p>
            <template v-if="newsInfo.textList.length">
                <div v-for="(item,index) in newsInfo.textList" :key="index">
                    <div class="text" v-html="item.text"></div>
                    <div class="img" v-if="item.img">
                        <img :src="item.url" :alt="item.alt">
                        <p class="img-text" v-if="item.imgText">{{item.imgText}}</p>
                    </div>
                    <div class="img-list" v-if="item.imgList">
                        <img class="imgList-img" :src="pic.url" :alt="pic.alt" v-for="(pic,index) in item.imgList" :key="`_${index}`">
                    </div>
                </div>
            </template>
            <p class="page-turning">
                <span v-if="newsId > 0" @click="pageTurning('prev')">上一篇</span>
                <span v-if="newsId < newsData.length - 1" @click="pageTurning('next')">下一篇</span>
            </p>
        </div>
    </div>
</template>

<script>
const newsData = require('../../static/data/news-list');
export default {
    name: "index",
    data () {
        return {
            newsId: this.$route.params.id || null,
            newsDataCopy: [],
            newsData: [],
            newsInfo: null,
            newsTypeOption: [
                {label: '正经塾', type: 10},
                {label: '亚商新闻', type: 20}
            ]
        }
    },
    created(){
        const {newsId} = this.$data;
        const newsDataCopy = JSON.parse(JSON.stringify(newsData));
        this.newsData = this.newsDataCopy = newsDataCopy;
        this.setNewsDataById(newsId);
    },
    watch: {
        '$route': function(route) {
            const {params} = route;
            this.newsId = params.id-0;
            this.setNewsDataById(this.newsId);
        }
    },
    methods: {
        //根据type设置对应类别
        formatNewsType(type) {
            return this.newsTypeOption.find(item => item.type === type).label;
        },
        setNewsDataById(newsId) {
            const newsInfo = this.newsDataCopy[newsId];
            const {textList} = newsInfo;
            newsInfo.textList = textList.map(item => {
                let {imgList} = item;
                if (imgList && imgList.length) {
                    imgList.forEach(pic => {
                        pic['url'] = pic.img ? require(`./images/${pic.img}`) : '';
                    })
                }
                return Object.assign({}, item, {url: item.img ? require(`./images/${item.img}`) : ''})
            });
            this.newsInfo = newsInfo;
        },
        //翻页
        pageTurning(msg) {
            if (msg === 'prev') {
                this.newsId--;
            } else {
                this.newsId++;
            }
            this.$router.push(`/news-old-detail/${this.newsId}`);
        },
        //返回浏览器历史上一页
        gobackNewsList(isAll) {
            if (isAll) {
                window.localStorage.setItem('category', '0');
            } else {
                window.localStorage.setItem('category', this.newsInfo.type);
            }
            this.$router.push('/news-old-list');
        },
        gotoIndex() {
            this.$router.push('/index');
            window.localStorage.setItem('category', '0');
        }
    }
}
</script>

<style lang="scss" scoped>
    .news{
        background: #F7F8FA;
        text-align: center;
        .banner{
            margin-top: 100px;
        }
        .breadcrumb{
            width: 1200px;
            text-align: left;
            margin: 20px auto;
            color: #717171;
            span{
                cursor: pointer;
                vertical-align: middle
            }
        }
        .news-content{
            width: 1200px;
            margin: 0 auto;
            color: #717171;
            font-size: 18px;
            background-color: #fff;
            padding: 60px 56px;
            box-sizing: border-box;
            .title{
                font-size:22px;
                font-weight:bold;
                line-height:29px;
                color: #2E353D;
            }
            .time{
                font-size: 14px;
                margin: 20px 0 30px 0;
            }
            .ml_20{
                margin-left: 20px;
            }
            .text{
                line-height:36px;
                margin-bottom: 10px;
                text-align: left;
                text-indent: 2em;
            }
            .font_bold{
                font-weight: bold;
            }
            .img{
                margin-bottom: 30px;
            }
            .img-list img{
                margin-right: 10px;
                margin-bottom: 15px;
            }
            .img-text{
                font-size: 14px;
                margin-top: 10px;
                color: #7d7d7d;
            }
            .page-turning{
                text-align: right;
                font-size: 18px;
                color: #717171;
                margin-top: 30px;
            }
            .page-turning span{
                margin-left: 40px;
                cursor: pointer;
            }
            .footer-tips{
                margin-left: -56px;
                margin-right: -56px;
                border-bottom: 1px solid #CFD5E1;
            }
            .footer-tips{
                div{
                    color: #AFBACE;
                    font-size: 14px;
                    line-height:28px;
                    text-align: left;
                    padding: 15px 56px;
                }
                .pt_0{
                    padding-top: 0;
                }
            }
            .color_gray{
                background-color: #F7F8FA;
            }
        }
    }
</style>
