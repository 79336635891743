<template>
    <div class="page-container" id="about">
        <img class="banner" src="./banner.jpg" width="100%" />
        <fixed-tab ref="fixedTab" :list="tabList" :offset-top="offsetTop" />
        <div id="tab1">
            <div class="about-introduction">
                <div class="a-i-content">
                    <p class="a-i-title">正经塾简介</p>
                    <p class="general-title mt_50">定位</p>
                    <ul class="clearfix">
                        <li>
                            <span><i class="iconfont icondaoshi"/></span>
                            <p class="title">高端研习平台</p>
                            <p class="desc">掌门人正经塾是一个为新时代商界领袖打造的、完全创新型的高端研习平台和人脉资源圈。</p>
                        </li>
                        <li>
                            <span><i class="iconfont iconrenzheng-"/></span>
                            <p class="title">对标30强上市公司</p>
                            <p class="desc">以每年度“中国最具健康成长力30强上市公
                                司”掌门人为研习楷模，以30强上市公司为
                                案例，与掌门人同行，上门学习、动态学习、
                                终生学习。</p>
                        </li>
                        <li>
                            <span><i class="iconfont iconzuzhicopy"/></span>
                            <p class="title">强大导师团队</p>
                            <p class="desc">由著名的“孙冶方基金会经济学奖”得主、亚商集团董事长陈琦伟创办。</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="home-get">
                <div class="h-g-content">
                    <p class="general-title">价值</p>
                    <ul class="clearfix">
                        <li>
                            <span><i class="iconfont iconrenwu" /></span>
                            <p class="title">与上市公司掌门人同行</p>
                            <p class="desc">由每年度“中国最具健康成长力30强上市公司”的掌门人为指导导师，进行动态案例研习，与塾生直接面对面深度交流“掌门之道”。</p>
                        </li>
                        <li>
                            <span><i class="iconfont icontiyanke" /></span>
                            <p class="title">塑造新一代商界精英</p>
                            <p class="desc">通过完全实战导向的沉浸体验式学 习，实质性提升塾生的企业家能力， 塑造新一代企业家和具有精英智慧 的价值投资者。</p>
                        </li>
                        <li>
                            <span><i class="iconfont iconrenmai" /></span>
                            <p class="title">构建顶层人脉资源圈</p>
                            <p class="desc">掌门人正经塾将通过研习过程，构建中国最优质的、志同道合的、面向未来的商界顶层人脉资源圈。</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="about-expand">
                <div class="a-e-content">
                    <p class="general-title mt_50">未来资源圈拓展</p>
                    <div class="a-e-warp">
                        <div class="center">
                            <p class="title">资源圈拓展</p>
                        </div>
                        <div class="a-e-modal one">
                            <p class="title">中国掌门人30俱乐部</p>
                            <p class="desc">
                                掌门人正经塾，将邀请导师掌门人组建“中国掌门人30俱乐部”，优秀塾生有机会成为俱乐部会员。
                            </p>
                        </div>
                        <div class="a-e-modal two">
                            <p class="title">高校学院联盟</p>
                            <p class="desc">
                                掌门人正经塾已与复旦大学管理学院、 上海交通大学安泰经管学院结为战略合 作关系，并将与更多优质院校组成联盟。
                            </p>
                        </div>
                        <div class="a-e-modal three">
                            <p class="title">动向游学活动</p>
                            <p class="desc">
                                掌门人正经塾，将与国内外著名大学合作推出“科技经济最前沿动向游学活动”，实质性开拓塾生的视野。
                            </p>
                        </div>
                        <div class="a-e-modal four">
                            <p class="title">终身定制化服务</p>
                            <p class="desc">
                                掌门人正经塾将为塾生提供深度定制化服务及终身增值服务。设立国内一流的塾生交流平台，为毕业后塾生的后续学习提供服务。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="founder-container" id="div2">
            <div class="founder-introduction">
                <div class="f-i-content">
                    <p class="f-i-title">创办人简介</p>
                    <div class="f-i-list clearfix">
                        <div class="fl">
                            <p class="title">陈琦伟&nbsp;先生</p>
                            <p class="desc">
                                连续十二年担任上交所公司治理专家咨询委员会委员。亚商集团创始人，董事长。亚商资本创始合伙人。上海交通大学安泰经济与管理学院教授，曾任上海股权投资协会理事长、中国创业资本研究中心主任，兼任中国国家开发银行顾问，中国企业家论坛理事，上海新沪商联合会轮值主席,多家金融机构和上市公司独立董事，上海证券交易所专家委员等。<br/>
                                专业领域及成就：投资银行和资本市场专家。其专业代表作《国际竞争论》曾获“孙冶方经济学优秀著作奖”。九十年代至今，领导亚商成长为国内享有盛名的投资管理和企业策略服务集团。
                            </p>
                        </div>
                        <div class="fr">
                            <img src="./cqw.png" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { addEvent, throttle } from '../../lib/utils';
	import FixedTab from '../../components/fixed-tab/index';
    let homeContentScrollHandleThrottle = null;
	export default {
		components: {
			FixedTab
		},
        data() {
			return {
				tabList: [
                    {name: '正经塾简介', id: 'about'},
					{name: '创办人简介', id: 'div2'}
                ],
				bannerHeight: 0,
                fixedTabPage: null
            }
        },
		computed: {
			offsetTop() {
				return this.bannerHeight;
			}
		},
        methods: {
			getBannerHeight() {
				const _this = this;
				const bannerEl = this.$el.querySelector('.banner');
				bannerEl.onload = function () {
					_this.bannerHeight = this.clientHeight;
                };
            },
          scrollHandle() {
            const selectedIndex = this.fixedTabPage.selectedIndex;
            const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if (scrollTop > 1800) {
              if (selectedIndex !== 1){
                this.fixedTabPage.changeSelectedHandle(1);
              }
            }else {
              if (selectedIndex !== 0){
                this.fixedTabPage.changeSelectedHandle(0);
              }
            }
          },
        },
        mounted() {
			this.$nextTick(() => {
                this.fixedTabPage = this.$refs.fixedTab;
				this.getBannerHeight();
                homeContentScrollHandleThrottle = throttle(this.scrollHandle, 10);
                addEvent(window, 'scroll', homeContentScrollHandleThrottle);
            })
        },
      beforeDestroy() {
        removeEventListener(window, 'scroll', this.scrollHandle);
      }
	}
</script>

<style lang="scss" scoped>
    .about-introduction{
        background-color: #fff;
        .a-i-content{
            @include section-mixin;
            padding-bottom: 50px;
            .a-i-title{
                text-align: center;
                font-weight:400;
                line-height:40px;
                color:rgba(46,53,61,1);
                margin-top: 75px;
                font-size: 30px;
            }
            ul {
                margin-top: 50px;
                li {
                    float: left;
                    width: 360px;
                    height: 350px;
                    padding: 60px 40px;
                    box-sizing: border-box;
                    box-shadow:0 3px 16px rgba(46,53,61,0.02);
                    background-color: rgba(255, 255, 255, .2);
                    text-align: center;
                    transition: all 0.3s ease;
                    &+li {
                        margin-left: 15px;
                    }
                    span i {
                        font-size: 57px;
                    }
                    .title {
                        font-size:24px;
                        font-weight:400;
                        line-height:31px;
                        color:rgba(46,53,61,1);
                        margin-top: 35px;
                    }
                    .desc{
                        font-size:16px;
                        font-weight:400;
                        line-height:28px;
                        color:rgba(132,132,132,1);
                        margin-top: 15px;
                        text-align: left;
                    }
                    &:hover{
                        transform: translateY(-6px);
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
    .home-get{
        background: url(./bg.jpg) bottom center fixed;
        padding: 100px 0;
        .h-g-content{
            @include section-mixin;
            .general-title{
                color: #fff;
            }
            ul {
                margin-top: 50px;
                li{
                    float: left;
                    width: 33.333%;
                    box-sizing: border-box;
                    text-align: center;
                    padding: 0 45px;
                    span{
                        display: inline-block;
                        width: 121px;
                        height: 121px;
                        line-height: 121px;
                        border-radius: 50%;
                        border:1px solid #fff;
                        i {
                            color: #fff;
                            font-size: 55px;
                        }
                    }
                    .title {
                        line-height:31px;
                        font-size:24px;
                        color: #fff;
                        margin-top: 24px;
                    }
                    .desc {
                        font-size:16px;
                        font-weight:400;
                        line-height:28px;
                        color:rgba(255,255,255,1);
                        margin-top: 15px;
                        text-align: left;
                    }
                }
            }
        }
    }
    .about-expand{
        background-color: #fff;
        .a-e-content{
            @include section-mixin;
            .a-e-warp{
                overflow: hidden;
                position: relative;
                height: 485px;
                margin-bottom: 20px;
                background: url("./expand.png") center center no-repeat;
                .center{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin: -20px 0 0 -75px;
                    p {
                        font-size:30px;
                        line-height:40px;
                        color:rgba(255,255,255,1);
                    }
                }
                .a-e-modal{
                    position: absolute;
                    width:306px;
                    &.one{
                        top: 101px;
                        left: 10px;
                    }
                    &.two{
                        top: 101px;
                        right: 10px;
                    }
                    &.three{
                        top: 348px;
                        left: 10px;
                    }
                    &.four{
                        top: 348px;
                        right: 10px;
                    }
                    .title{
                        font-size:24px;
                        font-weight:400;
                        line-height:31px;
                        color:rgba(46,53,61,1);
                        text-align: center;
                    }
                    .desc{
                        text-align: left;
                        font-size:16px;
                        font-weight:400;
                        line-height:28px;
                        color:rgba(132,132,132,1);
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .founder-container {
        background-color: #F7F8FA;
        overflow: hidden;
    }
    .founder-introduction {
        .f-i-content{
            @include section-mixin;
            padding-bottom: 50px;
            .f-i-title{
                text-align: center;
                font-weight:400;
                line-height:40px;
                color:rgba(46,53,61,1);
                margin-top: 75px;
                font-size: 30px;
            }
            .f-i-list{
                position: relative;
                width: 100%;
                background-color: #fff;
                height: 269px;
                margin-top: 50px;
                box-sizing: border-box;
                padding-right: 235px;
                .fl {
                    width: 100%;
                    padding: 30px 30px 30px 50px;
                    box-sizing: border-box;
                }
                .title{
                    font-size:24px;
                    font-weight:400;
                    line-height:31px;
                    color:rgba(46,53,61,1);
                    text-align: right;
                }
                .desc{
                    font-size:16px;
                    font-weight:400;
                    line-height:28px;
                    color:rgba(132,132,132,1);
                    margin-top: 15px;
                }
                .fr{
                    margin: -29px -235px 0 0;
                }
            }
            .f-i-list-fl{
                position: relative;
                width: 100%;
                background-color: #fff;
                height: 269px;
                margin-top: 50px;
                box-sizing: border-box;
                padding-left: 257px;
                .fr {
                    width: 100%;
                    padding: 30px 30px 30px 0;
                    box-sizing: border-box;
                }
                .title{
                    font-size:24px;
                    font-weight:400;
                    line-height:31px;
                    color:rgba(46,53,61,1);
                    text-align: left;
                }
                .desc{
                    font-size:16px;
                    font-weight:400;
                    line-height:28px;
                    color:rgba(132,132,132,1);
                    margin-top: 15px;
                }
                .fl{
                    margin: -38px 0 0 -286px;
                }
            }
        }
    }
</style>
