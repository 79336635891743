<template>
    <div class="back-top iconfont icondingbu" v-if="scrollTop > visibilityHeight" @click="scrollTopHandle"></div>
</template>

<script>
	export default {
		props: {
			target: {
				type: Function
			},
			visibilityHeight: {
				type: Number,
				default: 100
			}
		},
		data() {
			return {
				targetEle: null,
				scrollTop: 0
			}
		},
		computed: {
			globalScrollTop() {
				return this.$store.state.scrollTop;
			}
		},
		watch: {
			globalScrollTop(val) {
				this.$nextTick(() => {
					this.scrollTop = val;
				});
			}
		},
		mounted() {
			this.$nextTick(() => {
				const target = this.target;
				if (target && typeof target === 'function') {
					this.targetEle = target();
				}
			})
		},
		methods: {
			scrollTopHandle() {
				const smoothScroll = function () {
					const currentScroll = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
					if (currentScroll > 0) {
						window.requestAnimationFrame(smoothScroll);
						window.scrollTo(0, currentScroll - (currentScroll / 5));
					}
				};
				smoothScroll();
			}
		}
	}
</script>

<style lang="scss" scoped>
    .back-top {
        width: 76px;
        line-height: 76px;
        transition: top .2s;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-top: 20px;
        cursor: pointer;
        i {
            font-size: 30px;
            color: #848484;
        }
    }
</style>
