import Vue from 'vue'
import Router from 'vue-router'

import Index from '../views/index'
import StudySystem from '../views/study-system'
import About from '../views/about'
import Advisory from '../views/advisory'
import ContactUs from '../views/contact'
import StudyCase from '../views/study-case'
import StudyCaseDetail from '../views/study-case/detail'
import NewsList from '../views/news-list'
import NewsDetail from '../views/news-detail'
import NotFound from '../views/404'

Vue.use(Router);
const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: '首页',
			isNav: true,
			component: Index
		},
		{
			path: '/404',
			name: 'NotFound',
			isNav: false,
			component: NotFound
		},
		{
			path: '/study-system',
			name: '研习体系',
			isNav: true,
			component: StudySystem
		},
		{
			path: '/study-case',
			name: '研习案例',
			isNav: true,
			component: StudyCase
		},
		{
			path: '/old-study-case-detail/:id',
			name: '研习案例详情',
			isNav: false,
			component: StudyCaseDetail
		},
		{
			path: '/about',
			name: '关于我们',
			isNav: true,
			component: About
		},
		{
			path: '/contact-us',
			name: '联系我们',
			isNav: true,
			component: ContactUs
		},
		{
			path: '/advisory',
			name: '立即咨询',
			isNav: false,
			component: Advisory
		},
		{
			path: '/news-old-list',
			name: '资讯中心',
      isNav: false,
			component: NewsList
		},
		{
			path: '/news-old-detail/:id',
			name: '新闻详情',
      isNav: false,
			component: NewsDetail
		},
		{
			path: '/news-template',
			name: 'newsTemplate',
			component: () => import(/*webpackChunkName: 'news-template'*/ '../views/news-template/index')
		},
		{
			path: '/study-case-template',
			name: 'studyCaseTemplate',
			component: () => import(/*webpackChunkName: 'study-case-template'*/ '../views/study-case-template/index')
		},
		{
			path: '/study-case-detail-template-zjs',
			name: 'studyCaseDetailTemplate',
			component: () => import(/*webpackChunkName: 'study-case-detail-template'*/ '../views/study-case-detail-template/index')
		},
	]
});

router.afterEach(() => {
	window.scroll(0, 0);
});

export default router;
