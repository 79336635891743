<template>
  <div class="not-found-container">
    <img src="./images/not_found.png"/>
    <p class="not-found-title">您所访问的页面不存在！</p>
    <p class="not-found-content">资源不存在或者没有访问权限，<a href="/">点击这里</a>返回首页</p>
  </div>
</template>

<script>
	export default {
		name: "notFound"
	}
</script>

<style scoped>
  .not-found-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .not-found-title {
    margin-top: 40px;
    font-size:20px;
    font-weight:400;
    line-height:22px;
    color:rgba(54,54,54,1);
  }
  .not-found-content {
    margin-top: 15px;
    font-size:14px;
    font-weight:400;
    line-height:22px;
    color:rgba(115,115,115,1);
  }
  .not-found-content a {
    color: #409EFF;
  }
</style>
