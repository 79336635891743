<template>
    <div :class="['fixed-tab', isFixed ? 'fixed' : '']" v-if="list.length">
        <ul class="clearfix">
            <li :style="{width: listWidth}" v-for="(item, index) in list" :class="[selectedIndex === index ? 'active' : '']" :key="index">
                <span v-scroll-to="`#${item.id}`" @click="changeSelectedHandle(index)">{{ item.name }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
                default: function() {
                    return [];
                },
                require: true
            },
            offsetTop: {
				type: Number,
                require: true,
                default: 0
            }
        },
		data() {
			return {
				isFixed: false,
                selectedIndex: 0
			}
		},
		computed: {
			globalScrollTop() {
				return this.$store.state.scrollTop;
			},
			listWidth() {
				const length = this.list.length;
				return `${100/length}%`;
			}
		},
		watch: {
			globalScrollTop(val) {
				this.$nextTick(() => {
					// const {list} = this.$props;
					this.isFixed = val > this.offsetTop;
					// const offsetTopList = list.map(item => {
					// 	const {id} = item;
					// 	const _el = document.getElementById(id);
					// 	return _el.offsetTop - 100;
                    // });
				});
			},
            isFixed(val){
              if (!val){
                this.selectedIndex = 0;
              }
            }
		},
        methods: {
			changeSelectedHandle(index) {
				this.selectedIndex = index;
            }
        }
	}
</script>

<style lang="scss" scoped>
    .fixed-tab{
        position: relative;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        z-index: 999;
        &::after {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 0;
            background-color: #F2F4F6;
        }
        &.fixed {
            position: fixed;
            top: 100px;
            left: 0;
        }
        ul {
            @include section-mixin;
            li {
                float: left;
                text-align: center;
                span {
                    position: relative;
                    padding: 0 30px;
                    display: inline-block;
                    line-height: 70px;
                    cursor: pointer;
                    font-size: 24px;
                    color: #848484;
                }
                &.active {
                    span {
                        color: #2E353D;
                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            background-color: #B50707;
                            left: 0;
                            bottom: 0;
                            z-index: 100;
                        }
                    }
                }
            }
        }
    }
</style>
