<template>
    <div class="study">
        <div class="study-introduction clearfix" id="div1">
            <div class="study-introduction_left fl">
                <img src="./images/symbol.png" alt="">
                <p class="s-i_title">研习体系</p>
                <p>主要由研习内容、塾生制、研习理念三个模块构成，其中研习内容包含了“正经道“"正经谭"”正经汇“三部分，分别介绍了研习方式及对象，尊崇导师，学员交流形式等；塾生制即研习学制，正经塾学员统称“塾生”。以上连同研习理念，共同构成了正经塾的研习体系.</p>
                <ul class="icon-list">
                    <li>
                        <img src="./images/icon-dao.png" alt="" width="51">
                        <p>成功之道，避险之道</p>
                    </li>
                    <li>
                        <img src="./images/icon-shu.png" alt="" width="51">
                        <p>经商之术，运业之根</p>
                    </li>
                    <li>
                        <img src="./images/icon-shi.png" alt="" width="51">
                        <p>文明通识，艺文养心</p>
                    </li>
                    <li>
                        <img src="./images/icon-ti.png" alt="" width="51">
                        <p>美育修身，健体根基</p>
                    </li>
                </ul>
                <ul class="item-list">
                    <li class="line-right">
                        <h3>正经道+</h3>
                        <p>访天下名师, 悟掌门之道</p>
                    </li>
                    <li class="line-right">
                        <h3>正经谭+</h3>
                        <p>请行业大咖, 瞻时代之潮</p>
                    </li>
                    <li>
                        <h3>正经汇+</h3>
                        <p>汇同道中人，聚商业之慧</p>
                    </li>
                </ul>
            </div>
            <img class="fr" src="./images/yxtx-intro1.jpg" alt="" width="100%">
        </div>
        <fixed-tab ref="studySystemTab" :list="tabList" :offset-top="455" />
        <div class="study-content-main" id="study-content">
            <h2>研习内容</h2>
            <div class="study-content-zjd">
                <h3>正经道</h3>
                <p>访天下名师，悟掌门之道</p>
                <p class="mt_0">标杆企业实地游学研习走访，当面请经掌门人成功和避险之道。</p>
            </div>
            <div class="study-content-swiper">
                <p class="title">研习方式：动态案例研习</p>
                <div class="swiper" style="position: relative">
                    <swiper class="swiper" :options="swiperOption">
                        <swiper-slide>
                            <div class="swiper-slide-container clearfix">
                                <div class="swiper-slide-left">
                                    <p class="title">掌门人情怀和价值</p>
                                    <div class="desc">
                                        <p>掌门人事业情怀，价值观、愿景；</p>
                                        <p>掌门人核心转折点。</p>
                                    </div>
                                </div>
                                <div class="swiper-slide-right">
                                    <img width="473" src="./images/slide-1.jpg"/>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-slide-container clearfix">
                                <div class="swiper-slide-left">
                                    <p class="title">产业前景展望</p>
                                    <div class="desc">
                                        <p>未来的发展和利润的主要增长点；</p>
                                        <p>产业价值链和市场竞争格局的变化趋势；</p>
                                        <p>产业成功的关键因素和未来变化；</p>
                                        <p>产业壁垒；</p>
                                        <p>产业外生变量（政策等）的变化趋势；</p>
                                        <p>产业技术的发展趋势；</p>
                                        <p>产业发展潜在的风险点。</p>
                                    </div>
                                </div>
                                <div class="swiper-slide-right">
                                    <img width="473" src="./images/slide-2.jpg"/>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="swiper-slide-container clearfix">
                                <div class="swiper-slide-left">
                                    <p class="title">公司前景展望</p>
                                    <div class="desc">
                                      <p>核心战略、核心商业模式；</p>
                                      <p>核心竞争力、竞争策略；</p>
                                      <p>产品、销售、研发和资本策略；</p>
                                      <p>治理结构和激励计划；</p>
                                      <p>核心管理团队计划；</p>
                                      <p>公司潜在转折点。</p>
                                    </div>
                                </div>
                                <div class="swiper-slide-right">
                                    <img width="473" src="./images/slide-3.jpg"/>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                    <div class="swiper-active-num">
                        01
                    </div>
                    <div class="swiper-btns">
                        <span slot="button-prev" class="swiper-btn left"><i class="iconfont iconbaifangzhixiang"></i></span>
                        <span slot="button-next" class="swiper-btn right"><i class="iconfont iconbaifangzhixiang"></i></span>
                    </div>
                </div>
            </div>
            <div class="study-content-target">
                <p class="title">研习对象:“中国大师级企业家”和“最具健康成长力30强上市公司”掌门人</p>
                <div class="swiper-box" @mouseenter="mouseenterHandle" @mouseleave="mouseleaveHandle">
                    <swiper class="study-content-target_swiper" :options="targetSwiperOption">
                        <swiper-slide class="s-c-t_slide"><img src="./images/target1.png" alt="研习对象"></swiper-slide>
                        <swiper-slide class="s-c-t_slide"><img src="./images/target2.png" alt="研习对象"></swiper-slide>
                        <swiper-slide class="s-c-t_slide"><img src="./images/target3.png" alt="研习对象"></swiper-slide>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
            </div>
            <div class="study-content-zjt">
                <h3>正经谭</h3>
                <p>请行业大咖，瞻时代之潮通过论坛、专题研讨会、工作坊等形式，聚焦行业热点和痛点，邀请国内顶级嘉宾，持续为各方搭建碰撞思想、营造共识、携手合作、高端对话交流的平台。
                </p>
            </div>
            <div class="study-content-tutor" id="study-content-tutor">
                <p class="title">尊崇导师</p>
                <div class="tutor-major clearfix">
                    <div class="item">
                        <span><img src="./images/chengqiwei.png" alt="陈琦伟" /></span>
                        <div>
                            <p class="name">陈琦伟</p>
                            <p class="desc">亚商集团董事长、亚商资本创始合伙人</p>
                        </div>
                    </div>
                </div>
                <ul class="tutor-list clearfix">
                    <li>
                        <span><img src="./images/caodewang.png" alt="曹德旺"></span>
                        <p class="name">曹德旺
                        <p class="desc">福耀玻璃创始人</p>
                    </li>
                    <li>
                        <span class="special-img"><img src="./images/zqh.png" alt="宗庆后"></span>
                        <p class="name">宗庆后
                        <p class="desc">娃哈哈创始人</p>
                    </li>
                    <li>
                        <span><img src="./images/nancunhui.png" alt="南存辉"></span>
                        <p class="name">南存辉
                        <p class="desc">正泰集团创始人</p>
                    </li>
                    <li>
                        <span class="special-img"><img src="./images/cds.png" alt="陈东升"></span>
                        <p class="name">陈东升
                        <p class="desc">泰康集团创始人</p>
                    </li>
                    <li>
                        <span class="special-img"><img src="./images/ws.png" alt="王石"></span>
                        <p class="name">王石
                        <p class="desc">万科创始人</p>
                    </li>
                    <li>
                        <span><img src="./images/gaoshangquan.png" alt="高尚全"></span>
                        <p class="name">高尚全
                        <p class="desc">原国家体改委副主任</p>
                    </li>
                    <li>
                        <span><img src="./images/caihongping.png" alt="蔡洪平"></span>
                        <p class="name">蔡洪平</p>
                        <p class="desc">汉德工业4.0促进资本主席</p>
                    </li>
                    <li>
                        <span><img src="./images/yangchao.png" alt="杨超"></span>
                        <p class="name">杨超</p>
                        <p class="desc">原中国人寿保险董事长</p>
                    </li>
                    <li>
                        <span><img src="./images/zhouqiren.png" alt="周其仁"></span>
                        <p class="name">周其仁</p>
                        <p class="desc">北京大学国家发展研究院</p>
                        <p class="desc">经济学教授、著名经济学家</p>
                    </li>
                    <li>
                        <span><img src="./images/hongqi.png" alt="洪崎"></span>
                        <p class="name">洪崎</p>
                        <p class="desc">中国民生银行董事长</p>
                    </li>
                    <li>
                        <span><img src="./images/lidongsheng.png" alt="李东生" /></span>
                        <p class="name">李东生</p>
                        <p class="desc">TCL集团董事长</p>
                    </li>
                    <li>
                        <span><img src="./images/liuyonghao.png" alt="刘永好" /></span>
                        <p class="name">刘永好</p>
                        <p class="desc">新希望集团董事长</p>
                    </li>
                    <li>
                        <span><img src="./images/yangyuanqing.png" alt="杨元庆" /></span>
                        <p class="name">杨元庆</p>
                        <p class="desc">联想集团董事长&CEO</p>
                    </li>
                    <li>
                        <span><img src="./images/shennanpeng.png" alt="沈南鹏" /></span>
                        <p class="name">沈南鹏</p>
                        <p class="desc">红杉资本全球执行合伙人</p>
                    </li>
                    <li>
                        <span><img src="./images/zhanglei.png" alt="张磊" /></span>
                        <p class="name">张磊</p>
                        <p class="desc">高瓴资本创始人兼CEO</p>
                    </li>
                    <li class="mb_0">
                        <span><img src="./images/yanyan.png" alt="阎焱" /></span>
                        <p class="name">阎焱</p>
                        <p class="desc">赛富投资基金首席合伙人</p>
                    </li>
                    <li class="mb_0">
                        <span><img src="./images/weizhe.png" alt="卫哲" /></span>
                        <p class="name">卫哲</p>
                        <p class="desc">嘉御基金创始合伙人</p>
                    </li>
                </ul>
            </div>
            <div class="study-content-zjh" id="div2">
                <h3>正经汇</h3>
                <p class="desc">汇同道中人，聚商业之慧</p>
                <div class="zjh-classify clearfix">
                    <div class="fl">
                        <img src="./images/student-icon.png" alt="学员交流">
                        <div class="student">
                            <p class="title">高品质健康社交圈层</p>
                            <p>学员企业互访</p>
                            <p>运动团体交流&联名挑战赛等</p>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="fr">
                        <img src="./images/master-icon.png" alt="年度大师汇">
                        <div class="master">
                            <p class="title">年度大师汇</p>
                            <p>中国年度30强最具健康成长力榜单发布</p>
                            <p>中国年度最具商业价值的行业发布</p>
                            <p>掌门人正经塾年度峰会</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="study-content-ssz" id="ssz">
            <h2>塾生制</h2>
            <p class="pattern">1+N终身研习</p>
            <div class="ssz clearfix">
                <div class="fl">
                    <img src="./images/ssz-icon.png" alt="塾生制">
                    <div class="inline-class">
                        <p class="title">第一年</p>
                        <p>以九个月为一个基础研习周期     共九次必修研习</p>
                    </div>
                </div>
                <div class="fr">
                    <img src="./images/ssz-icon.png" alt="塾生制">
                    <div class="inline-class">
                        <p class="title">第二年~N年</p>
                        <p>塾生可选修正经塾当年的研习课程</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="study-content-idea" id="study-idea">
            <h2>研习理念</h2>
            <ul class="clearfix">
                <li>
                    <img src="./images/yan-icon.png" alt="">
                    <p>最具健康成长力</p>
                    <p>上市公司30强成功之术</p>
                </li>
                <li class="w_58">
                    <img src="./images/gan-icon.png" alt="">
                    <p>最优秀掌门人</p>
                    <p>经商、修身之道</p>
                </li>
                <li>
                    <img src="./images/wu-icon.png" alt="">
                    <p>最前沿产业未来</p>
                    <p>发展商机、事业人生之慧</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { throttle, addEvent } from '../../lib/utils';
    import FixedTab from '../../components/fixed-tab/index';
    import 'swiper/dist/css/swiper.css';
    import {swiper, swiperSlide} from 'vue-awesome-swiper';

    let homeContentScrollHandleThrottle = null;
    export default {
        components: {
            swiper,
            swiperSlide,
            FixedTab
        },
        data(){
            return {
                fixedActive: 0,
                swiperOption: {
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'progressbar'
                    },
                    effect: 'fade',
                    loop: true,
                    autoplay: {disableOnInteraction: false, delay: 3500},
                    speed: 1000,
                    fade: {
                        crossFade: false
                    },
                    navigation: {
                        nextEl: '.swiper-btn.right',
                        prevEl: '.swiper-btn.left'
                    },
                    on: {slideChange: function () {document.querySelector('.swiper-active-num').innerHTML = `0${this.realIndex + 1}`}}
                },
                targetSwiperOption: {
                    slidesPerView: 'auto',
                    loop : true,
                    spaceBetween: 0,
                    centeredSlides: true,
                    autoplay: {
                        disableOnInteraction: false,
                        delay: 3500
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                tabList: [{name: '研习内容', id: 'div1'}, {name: '塾生制', id: 'div2'}, {name: '研习理念', id: 'study-idea'}],
                prevBtn: null,
                nextBtn: null,
                studySystemTabRef: null
            }
        },
        mounted(){
            this.$nextTick( () => {
                const prev = document.querySelector('.swiper-button-prev');
                const next = document.querySelector('.swiper-button-next');
                this.prevBtn = prev;
                this.nextBtn = next;
                this.studySystemTabRef = this.$refs.studySystemTab;
                homeContentScrollHandleThrottle = throttle(this.scrollHandle, 10);
                addEvent(window, 'scroll', homeContentScrollHandleThrottle);
            })
        },
        methods: {
          scrollHandle(){
            const selectedIndex = this.studySystemTabRef.selectedIndex;
            const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if (scrollTop > 3500) {
              if (selectedIndex !== 1){
                this.studySystemTabRef.changeSelectedHandle(1);
              }
              if (scrollTop > 4040){
                if (selectedIndex >= 1){
                  this.studySystemTabRef.changeSelectedHandle(2);
                }
              }
            }
          },
            mouseenterHandle(){
                this.prevBtn.style.display = 'block';
                this.nextBtn.style.display = 'block';
            },
            mouseleaveHandle(){
                this.prevBtn.style.display = 'none';
                this.nextBtn.style.display = 'none';
            }
        },
      beforeDestroy(){
          removeEventListener(window, 'scroll', this.scrollHandle);
      }
    }
</script>

<style lang="scss" scoped>
    .study{
        width: 100%;
        background-color: #F7F8FA;
        font-size: 16px;
        margin-top: 100px;
        overflow: hidden;
        .study-introduction{
            @include section-mixin;
            padding-left: 50px;
            box-sizing: border-box;
            background-color: #fff;
            margin: 50px auto;
            overflow: hidden;
            .fr{
                width: 408px;
                overflow: hidden;
            }
        }
        .study-introduction_left{
            position: relative;
            width: 605px;
            padding: 56px 0 30px 40px;
            box-sizing: border-box;
            .s-i_title{
                font-size: 24px;
                color: #2E353D;
                margin-bottom: 20px;
            }
            > img{
                position: absolute;
                top: 22px;
                left: 0;
            }
            h2{
                font-size: 24px;
                color: #2E353D;
                margin-bottom: 14px;
            }
            p{
                /*font-size: 16px;*/
                color: #848484;
                line-height: 28px;
            }
            ul.icon-list{
                padding: 25px 0 30px 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                li{
                    text-align: center;
                    img{
                        margin-bottom: 4px;
                    }
                    p{
                        font-size: 12px;
                        color: #5F5E5E;
                    }
                }
            }
            .item-list{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                li{
                    /*width: 33.3%;*/
                    text-align: left;
                    position: relative;
                }
                .line-right:after{
                    position: absolute;
                    top: 0;
                    right: -46px;
                    display: inline-block;
                    content: '';
                    width: 1px;
                    height: 100%;
                    border-right: 1px dashed #5F5E5E;
                    overflow: hidden;
                    clear: both;
                }
                h3{
                    font-size: 18px;
                    font-weight: bold;
                    color: #981D2D;
                    margin-bottom: 2px;
                }
                p{
                    font-size: 12px;
                    color: #5F5E5E;
                }
            }
        }
        .study-fixed{
            width: 100%;
            background-color: #fff;
            height: 70px;
            z-index: 9999;
            position: static;
            border-bottom: 1px solid #F2F4F6;
            .study-fixed-content{
                @include section-mixin;
                height: 70px;
                li{
                    float: left;
                    width: 33.3%;
                    height: 70px;
                    line-height: 70px;
                    text-align: center;
                    font-size: 24px;
                    color: #848484;
                    span{
                        width: 175px;
                        display: inline-block;
                        cursor: pointer;
                    }
                    &.active{
                        color: #2E353D;
                        span{
                            border-bottom: 1px solid #B50707;
                        }
                    }
                }
            }
            &.fixed-name{
                position: fixed;
                top: 100px;
                left: 0;
            }
        }
        .study-content-main{
            padding-top: 70px;
            background-color: #fff;
            text-align: center;
            h2{
                font-size:30px;
                line-height: 40px;
                color: #2E353D;
                margin-bottom: 50px;
                &::after{
                    display: block;
                    content: '';
                    overflow: hidden;
                    width: 60px;
                    height: 3px;
                    margin: 4px auto 0;
                    background-color: #B50707;
                }

            }
            .study-content-zjd{
                height: 198px;
                background: url("./images/zjd-bg-fixed.jpg") bottom center fixed;
                color: #fff;
                padding-top: 60px;
                box-sizing: border-box;
                h3{
                    font-size: 24px;
                    font-weight: bold;
                    &::after{
                        display: block;
                        content: '';
                        width: 50px;
                        height: 1px;
                        background-color: #FFFFFF;
                        margin: 6px auto 0;
                    }
                }
                p{
                    @include section-mixin;
                    line-height: 28px;
                    margin-top: 14px;
                }
                .mt_0{
                    margin-top: 0;
                }
            }
        }
        .study-content-swiper{
            background-color: #F7F8FA;
            padding: 60px 0;
            .title{
                font-size: 24px;
                color: #2E353D;
                margin-bottom: 50px;
            }
            .swiper{
                height: 354px;
                @include section-mixin
            }
            .swiper-active-num{
                position: absolute;
                left: 0;
                top: 248px;
                z-index: 100;
                font-size:40px;
                font-weight:400;
                line-height:52px;
                color:rgba(46,53,61,1);
            }
            .swiper-btns{
                position: absolute;
                left: 280px;
                top: 268px;
                z-index: 100;
                .swiper-btn {
                    display: inline-block;
                    cursor: pointer;
                    outline: 0;
                    i {
                        font-size: 36px;
                    }
                    &.left {
                        transform: rotate(180deg);
                    }
                    &.right {
                        margin-left: 30px;
                    }
                    &.swiper-button-disabled{
                        cursor: no-drop;
                        i {
                            color: #848484;
                        }
                    }
                }
            }
            .swiper-container-horizontal > .swiper-pagination-progressbar{
                position: absolute;
                width: 228px;
                left: 0;
                top: 300px;
                background: rgba(132, 132, 132, .5);
                /deep/ .swiper-pagination-progressbar-fill{
                    background: #2E353D;
                }
            }
            .swiper-slide-container{
                height: 354px;
                padding-right: 473px;
                box-sizing: border-box;
                background-color: #F7F8FA;
                .swiper-slide-left{
                    float: left;
                    text-align: left;
                    width: 384px;
                    .title {
                        font-size:22px;
                        font-weight:400;
                        line-height:29px;
                        color:rgba(46,53,61,1);
                        margin: 40px 0 0 0;
                    }
                    .desc {
                        font-size:16px;
                        font-weight:400;
                        line-height:24px;
                        color:rgba(132,132,132,1);
                        margin-top: 15px;
                    }
                }
                .swiper-slide-right{
                    float: right;
                    margin-right: -473px;
                }
            }
        }
        .study-content-target{
            padding: 60px 0;
            .title{
                font-size: 24px;
                color: #2E353D;
                margin-bottom: 50px;
            }
            .swiper-box{
                @include section-mixin;
            }
        }
        .study-content-target_swiper{
            @include section-mixin
            .s-c-t_slide{
                width: 768px;
            }
            .swiper-button-prev, .swiper-button-next{
                display: none;
            }
            .swiper-button-prev{
                background-image: url("../index/swiper-button.png");
            }
            .swiper-button-next{
                background-image: url("../index/swiper-button.png");
                transform: rotate(180deg);
            }
            .swiper-button-prev, .swiper-button-next{
                background-size: 32px 32px;
            }
        }
        .study-content-zjt{
            height: 224px;
            background: url("./images/zjt-bg-fixed.jpg") bottom center fixed;
            color: #fff;
            padding: 60px 0;
            box-sizing: border-box;
            h3{
                font-size: 24px;
                font-weight: bold;
                &::after{
                    display: block;
                    content: '';
                    width: 50px;
                    height: 1px;
                    background-color: #FFFFFF;
                    margin: 6px auto 0;
                }
            }
            p{
                @include section-mixin
                line-height: 28px;
                margin-top: 14px;
            }
        }
        .study-content-tutor{
            padding: 60px 0;
            background-color: #F7F8FA;
            .title{
                font-size: 24px;
                color: #2E353D;
                margin-bottom: 100px;
            }
            ul{
                @include section-mixin
            }
            .tutor-major{
                margin-bottom: 78px;
                .item{
                    width: 558px;
                    height: 160px;
                    margin: 0 auto 20px;
                    background-color: #ffffff;
                    position: relative;
                    padding-top: 67px;
                    box-sizing: border-box;
                }
                span{
                    display: inline-block;
                    width: 114px;
                    height: 114px;
                    border-radius: 50%;
                    overflow: hidden;
                    position: absolute;
                    top: -57px;
                    left: 50%;
                    margin-left: -57px;
                }
                .name{
                    font-size: 18px;
                    margin: 10px 0;
                }
                .desc{
                    font-size: 16px;
                    margin-bottom: 6px;
                }
            }
            .tutor-list{
                li{
                    width: 217px;
                    margin-right: 10px;
                    margin-bottom: 78px;
                }
                li:nth-child(5),li:nth-child(10),li:nth-child(15){
                    margin-right: 0;
                }
                .mb_0{
                    margin-bottom: 0;
                }
            }
            li{
                float: left;
                height: 160px;
                background-color: #fff;
                position: relative;
                padding-top: 67px;
                box-sizing: border-box;
                margin-bottom: 20px;
                span{
                    display: inline-block;
                    width: 114px;
                    height: 114px;
                    border-radius: 50%;
                    overflow: hidden;
                    position: absolute;
                    top: -57px;
                    left: 50%;
                    margin-left: -57px;
                }
                .special-img{
                    width: 132px;
                    height: 132px;
                    top: -66px;
                    margin-left: -66px;
                }
                img {
                    width: 100%;
                }
                .name{
                    font-size: 18px;
                    margin: 10px 0;
                }
                .desc{
                    font-size: 16px;
                    margin-bottom: 6px;
                }
            }
        }
        .study-content-zjh{
            text-align: center;
            color: #fff;
            height: 370px;
            background: url("./images/zjd-bg-fixed.jpg") bottom center fixed;
            padding-top: 60px;
            box-sizing: border-box;
            h3{
                font-size: 24px;
                font-weight: bold;
                &::after{
                    display: block;
                    content: '';
                    width: 50px;
                    height: 1px;
                    background-color: #FFFFFF;
                    margin: 6px auto 0;
                }
            }
            .desc{
                @include section-mixin
                line-height: 28px;
                margin-top: 14px;
                margin-bottom: 36px;
            }
            .zjh-classify{
                @include section-mixin
                > div{
                    width: 542px;
                    height: 166px;
                    background:rgba(255,255,255,0.53);
                    padding-top: 22px;
                    box-sizing: border-box;
                }
                img{
                    vertical-align: top;
                    margin-top: 30px;
                }
                .line{
                    display: inline-block;
                    width: 1px;
                    height: 166px;
                    border-right: 1px dashed #fff;
                }
            }
            .student, .master{
                display: inline-block;
                text-align: left;
                margin-left: 40px;
                .title{
                    font-size: 24px;
                    margin-bottom: 12px;
                }
                p{
                    line-height: 28px;
                }
            }
        }
        .study-content-ssz{
            height: 240px;
            padding-top: 60px;
            box-sizing: border-box;
            background-color: #fff;
            text-align: center;
            h2{
                font-size: 30px;
                color: #2E353D;
                margin-bottom: 30px;
                &::after{
                    display: block;
                    content: '';
                    overflow: hidden;
                    width: 60px;
                    height: 3px;
                    margin: 4px auto 0;
                    background-color: #B50707;
                }
            }
            .pattern{
                color: #848484;
                font-size: 18px;
                margin-bottom: 30px;
            }
            .ssz{
                @include section-mixin
                img{
                    vertical-align: top;
                }
                .inline-class{
                    display: inline-block;
                    text-align: left;
                    margin-left: 20px;
                }
                .title{
                    font-size: 24px;
                    margin-bottom: 12px;
                }
            }
            .ssz > div{
                width: 535px;
                height: 105px;
                background-color: #fff;
                box-shadow:0px 3px 16px rgba(46,53,61,0.16);
                padding: 20px 0 20px 30px;
                box-sizing: border-box;
                text-align: left;
            }
        }
        .study-content-idea{
            padding-bottom: 60px;
            padding-top: 114px;
            background-color: #F7F8FA;
            text-align: center;
            h2{
                font-size: 30px;
                color: #2E353D;
                margin-bottom: 50px;
                &::after{
                    display: block;
                    content: '';
                    overflow: hidden;
                    width: 60px;
                    height: 3px;
                    margin: 4px auto 0;
                    background-color: #B50707;
                }
            }
            ul{
                @include section-mixin
            }
            li{
                float: left;
                width: 21%;
                text-align: center;
                img{
                    margin-bottom: 15px;
                }
                p{
                    line-height: 28px;
                    color: #2E353D;
                }
            }
            li.w_58{
                width: 58%;
            }
        }
    }
</style>
