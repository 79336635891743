<template>
    <article id="app">
        <app-header v-if="!is404" />
        <section class="app-content" v-if="!isNewsTemplate">
            <router-view/>
        </section>
        <router-view v-else />
        <app-footer v-if="!is404"/>
        <float-bar v-if="!is404"/>
    </article>
</template>

<script>
	import {addEvent, removeEventListener, throttle} from "./lib/utils";
	import {mapMutations} from 'vuex'
	import AppHeader from './components/header'
	import AppFooter from './components/footer'
	import FloatBar from './components/float-bar'

	let homeContentScrollHandleThrottle = null;
	export default {
		name: 'app',
		components: {
			AppHeader,
			AppFooter,
			FloatBar
		},
		computed: {
			is404() {
				return this.$route.fullPath.indexOf('404') > -1;
			},
			isNewsTemplate() {
				return this.$route.fullPath.indexOf('news-template') > -1;
			},
		},
		mounted() {
			this.$nextTick(() => {
				homeContentScrollHandleThrottle = throttle(this.scrollHandle, 10);
				addEvent(window, 'scroll', homeContentScrollHandleThrottle);
			})
		},
		methods: {
			...mapMutations(['setGlobalScrollTop']),
			scrollHandle() {
				const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
				this.setGlobalScrollTop(scrollTop);
			},
		},
		beforeDestroy() {
			removeEventListener(window, 'scroll', this.scrollHandle);
		}
	}
</script>

<style lang="scss">
    html, body {
        height: 100%;
    }
    .amap-info-contentContainer{
      box-shadow: 5px 15px 12px rgba(0,0,0,.3);
    }
    #app {
        height: 100%;
    }

    .app-content {
        box-sizing: border-box;
        padding-bottom: 295px;
        min-height: 100%;
        min-width: $actualContainerWidth;
    }
    .page-container{
        margin-top: 100px;
        font-size: 0; //清除空格
    }
    .fl {
        float: left;
    }
    .mt_50{
        margin-top: 50px;
    }
    .mt_60{
        margin-top: 60px;
    }
    .mt_70{
        margin-top: 70px;
    }
    .mt_80{
        margin-top: 80px;
    }
    .general-title{
        position: relative;
        line-height:40px;
        font-size: 30px;
        color: #2E353D;
        text-align: center;
        &::after{
            content: '';
            position: absolute;
            width: 60px;
            height: 3px;
            background-color: #B50707;
            bottom: -6px;
            left: 50%;
            margin-left: -30px;
        }
        .more{
            float: right;
            cursor: pointer;
            color: #848484;
            font-size: 14px;
        }
    }
</style>
