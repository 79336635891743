import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill'
import './static/css/index.css';
import VueScrollTo from 'vue-scrollto'

import router from './router'

import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import {Table, TableColumn, Pagination} from 'element-ui'
import '../src/static/css/fonts.css'

//解决ie babel不识别promise
window.Promise = Promise;

Vue.config.productionTip = false;
Vue.use(Pagination)
Vue.use(Table)
Vue.use(TableColumn)

Vue.use(VueScrollTo);

new Vue({
	render: h => h(App),
	router,
	store,
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app');
