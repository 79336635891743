<template>
    <header :class="`app-header ${isTransparent ? 'is-transparent' : ''}`">
        <div class="app-header-container">
            <h1><a href="/" role="button">正经塾</a></h1>
            <div class="app-header-right">
                <nav>
                    <ul class="clearfix">
                        <li v-for="(nav, index) in navList" :key="index">
                            <a :class="[nav.completePathname.includes(pathname) ? 'active' : '']" :href="nav.href" role="button">
                                {{ nav.name }}</a>
                        </li>
                    </ul>
                </nav>
                <div class="hot-line">
                    <p><img src="./tel.png" alt/>咨询热线</p>
                    <p>021—62959001</p>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
	import {originHrefMap} from '../../conf/index';

	let homeBannerEl = null;
	let homeBannerHeight = 0;

	export default {
		data() {
			return {
				isTransparent: false,
				pathname: '',
				currentHref: '',
				origin: '',
				navList: []
			}
		},
		computed: {
			globalScrollTop() {
				return this.$store.state.scrollTop;
			}
		},
		watch: {
			globalScrollTop(val) {
				this.$nextTick(() => {
					this.isTransparent = val < homeBannerHeight - 100;
				});
			}
		},
        mounted() {
			this.$nextTick(() => {
				if (this.pathname === '/') {
					if (!homeBannerEl) {
						homeBannerEl = document.getElementById('home_banner');
						homeBannerEl.onload = function () {
							homeBannerHeight = this.clientHeight;
						};
					}
				}
            })
        },
		created() {
			this.getNavList();
		},
		methods: {
			getNavList() {
				const {NODE_ENV} = process.env;
				const {options: {routes}} = this.$router;
				const {href, pathname} = window.location;
				const origin = originHrefMap[NODE_ENV].origin;
				this.pathname = pathname;
				if (pathname === '/') {
					this.isTransparent = true;
				}
				this.origin = origin;
				this.currentHref = href;
				this.navList = routes.filter(item => item.isNav).map(item => ({...item, ...{href: `${item.path}`, completePathname: [`${item.path}`, `${item.path}/`]}}));
			}
		}
	}
</script>

<style lang="scss" scoped>
    .app-header {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 200;
        background-color: #fff;
        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background-color: #F2F4F6;
            bottom: 0;
            left: 0;
        }
        &.is-transparent {
            background-color: transparent;
            &:after{
                display: none;
            }
            .app-header-container {
                h1 a {
                    background: url("./white-logo.png") center center no-repeat;
                }
                nav li a {
                    color: #fff;
                }
                .hot-line p {
                    color: #fff;
                }
            }
        }
        .app-header-container {
            @include section-mixin;
            overflow: hidden;
            h1 {
                float: left;
                a {
                    display: inline-block;
                    width: 134px;
                    height: 54px;
                    margin-top: 30px;
                    background: url("./logo.png") center center no-repeat;
                    text-indent: -10000px
                }
            }
            .app-header-right {
                float: right;
            }
            nav {
                float: left;
                li {
                    float: left;
                    a {
                        position: relative;
                        display: inline-block;
                        height: 100px;
                        line-height: 100px;
                        padding: 0 20px;
                        font-size: 18px;
                        font-weight: 400;
                        color: #2E353D;
                        &::after {
                            transition: all .5s;
                            position: absolute;
                            content: '';
                            width: 24px;
                            height: 3px;
                            left: 50%;
                            top: 50%;
                            margin: 12px 0 0 -12px;
                            background-color: transparent;
                        }
                        &:hover, &.active {
                            font-weight: 700;
                            &::after{
                                background-color: #B50707;
                            }
                        }
                    }
                }
            }
            .hot-line {
                float: left;
                height: 100%;
                padding: 23px 0;
                margin-left: 65px;
                text-align: center;
                p {
                    font-size: 20px;
                    line-height: 30px;
                    color: rgba(46, 53, 61, 1);
                    img {
                        vertical-align: sub;
                        margin-right: 5px;
                    }
                    & + p {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                    }
                }
            }
        }
    }
</style>
