<template>
    <section class="news-list">
        <div class="banner"><img src="./images/banner.jpg" alt="资讯中心" width="100%"></div>
        <div class="breadcrumb">
            <span @click="gotoIndex">首页</span>
            >
            <font color="#2E353D">资讯中心</font>
        </div>
        <div class="content clearfix">
            <ul class="content-left fl">
                <li v-for="(item,index) in categoryList" :key="index" :class="(index === activeIndex && activeIndex !== 0) ? 'active' : (index === 0 && activeIndex === 0) ? 'activeAll' : ''" @click="switchCategory(index)">{{item}}</li>
            </ul>
            <div class="content-right fr">
                <ul class="c-r-ul" v-if="newsList.length">
                    <li v-for="(news,index) in newsList" :key="index" @click="gotoDetail(news.id)">
                        <span class="over-ellipse">{{news.title}}</span>
                        <span class="fr">{{news.time}}</span>
                    </li>
                </ul>
                <p class="no-data" v-else>暂无数据</p>
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </section>
</template>

<script>
const news = require('../../static/data/news-list');
export default {
    name: "index",
    data () {
        return {
            categoryList: ['全部', '正经塾', '亚商新闻'],
            newsList: [],
            originAllNewsList: [],
            categoryNewsList: [],
            currentPage: 1,
            activeIndex: 0,
            total: 0
        }
    },
    created(){
        this.setAllNews();
        this.getLocalStorageData();
    },
    methods: {
        setAllNews(){
            let currentList = [];
            currentList = this.categoryNewsList = this.originAllNewsList = JSON.parse(JSON.stringify(news));
            currentList = currentList.sort( (a, b) => {
                const aTime = new Date(a.time);
                const bTime = new Date(b.time);
                return bTime - aTime;
            });
            this.newsList = currentList;
            this.total = this.newsList.length;
        },
        //获取本地存储的新闻类型
        getLocalStorageData(){
            const defaultCategory = window.localStorage.getItem('category') ? JSON.parse(window.localStorage.getItem('category')) : null;
            if(!defaultCategory){
                return
            }
            const category = defaultCategory.toString();
            window.console.log(category.substring(0, 1));
            this.switchCategory(category.substring(0, 1));
        },
        handleSizeChange(val){
            window.console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // window.console.log(`当前页: ${val}`);
            let offset = (val-1)*10;
            let list = JSON.parse(JSON.stringify(this.categoryNewsList));
            let arr = list.splice(offset, 10);
            this.newsList = arr;
        },
        //点击新闻类别
        switchCategory(index){
            this.activeIndex = index-0;
            this.currentPage = 1;
            let type = index * 10;
            let results = [];
            this.newsList = [];
            window.localStorage.setItem('category', type);
            if(type === 0){
                let currentList = [];
                currentList = this.categoryNewsList = this.originAllNewsList;
                currentList = currentList.sort( (a, b) => {
                    const aTime = new Date(a.time);
                    const bTime = new Date(b.time);
                    return bTime - aTime;
                });
                this.newsList = currentList;
                this.total = currentList.length;
                return
            }
            results = news.filter( item => item.type === type);
            this.newsList = this.categoryNewsList = results;
            this.total = this.newsList.length;
        },
        //跳转到新闻详情页
        gotoDetail(newsId){
            this.$router.push(`/news-old-detail/${newsId}`);
        },
        gotoIndex(){
            this.$router.push('/');
            window.localStorage.setItem('category', '0');
        }
    }
}
</script>

<style lang="scss" scoped>
.news-list {
    background-color: #FAFBFC;
    text-align: left;
    .banner{
        margin-top: 100px;
    }
    .breadcrumb {
        @include section-mixin
        padding: 20px 0 40px 0;
        box-sizing: border-box;
        font-size: 18px;
        span {
            color: #717171;
            cursor: pointer;
            vertical-align: middle;
        }
    }
    .content {
        @include section-mixin
        padding-bottom: 16px;
        .content-left {
            width: 102px;
            border-right: 1px solid #DEDFE0;
            li {
                height: 66px;
                line-height: 66px;
                font-size: 20px;
                text-align: center;
                color: #2E353D;
                cursor: pointer;
                &:first-child {
                    font-weight: 600;
                }
            }
            li.active {
                color: #B50707;
                border-bottom: 1px solid #B50707;
            }
            li.activeAll {
                font-weight: 600;
                color: #B50707;
            }
        }
        .content-right {
            width: 982px;
            background-color: #ffffff;
            padding: 0 60px 60px 60px;
            box-sizing: border-box;
            color: #2E353D;
            font-size: 18px;
            .no-data {
                text-align: center;
                line-height: 40px;
                margin: 135px 0;
                color: #717171;
            }
            ul.c-r-ul {
                height: 650px;
                overflow: hidden;
            }
            li {
                padding: 20px 0;
                height: 24px;
                line-height: 30px;
                border-bottom: 1px solid #E2E2E2;
                cursor: pointer;
                position: relative;
                .over-ellipse {
                    display: inline-block;
                    width: 100%;
                    padding-right: 110px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    box-sizing: border-box;
                }
                span:last-child {
                    position: absolute;
                    right: 0;
                    top: 20px;
                    color: #717171;
                }
            }
        }
        .el-pagination {
            text-align: center;
            margin-top: 40px;
        }
    }
}
</style>
