import {originHrefMap} from '../conf/index';

export const addEvent = (dom, ev, fn) => {
	if (dom.attachEvent) {
		dom.attachEvent(`on${ev}`, fn)
	} else {
		dom.addEventListener(ev, fn, false);
	}
};

export const removeEventListener = (ele, ev, fn) => {
	if (ele.removeEventListener) {
		ele.removeEventListener(event, fn, false);
	} else {
		ele.detachEvent('on' + event, fn.bind(ele));
	}
};


export const throttle = (cb, delay) => {
	let timer = null;
	const throttle = function() {
		if (!timer) {
			timer = setTimeout(function() {
				clearTimeout(timer);
				timer = null;
				cb.apply(this, arguments)
			}, delay)
		}
	};
	throttle.cancel = function() {
		clearTimeout(timer);
		timer = null;
	};
	return throttle;
};

export const formatterToHref = path => {
	const {NODE_ENV} = process.env;
	const origin = originHrefMap[NODE_ENV].origin;
	return `${origin}${path}`;
};
